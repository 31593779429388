<template>
  <section class="container970">
    <el-card class="clubTop">
      <h1>BECOME A CLUB 100 MEMBER</h1>
      <p style="color:#333333" v-html="intro"></p>
      <div class="d-flex top justify-content-between">
        <div style="width: 125px"></div>
        <h3>How to register?</h3>
        <div>
          <el-button type="primary" @click="getClub100History()" round plain
            >Edit Details</el-button
          >
        </div>
      </div>
      <el-form
        label-position="top"
        label-width="80px"
        :model="form"
        ref="form"
        :rules="rules"
      >
        <el-form-item class="star" label="Company Name">
          <el-input
            v-model="form.company_name"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item class="star" label="Email">
          <!-- <el-input v-model="form.email"></el-input> -->
          <el-select
            v-model="user"
            style="width: 100%"
            filterable
            placeholder="Please Select"
            :filter-method="filterFriends"
            clearable
            popper-class="optionsContent"
            value-key="email"
            @change="chooseUser()"
          >
            <el-option
              v-for="item in userList"
              :key="item.user_id"
              :label="item.email"
              :value="item"
            >
              <el-table
                :data="[item]"
                style="width: 100%"
                :show-header="false"
                tooltip-effect="light"
              >
                <el-table-column prop="date" width="60">
                  <template slot-scope="scope">
                    <div class="flex justify-end">
                      <el-avatar
                        icon="el-icon-user-solid"
                        :src="scope.row.avatar"
                        :size="30"
                      >
                      </el-avatar>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="real_name" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="email" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="mobile" show-overflow-tooltip>
                </el-table-column>
              </el-table>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="star" label="First Name">
          <el-input
            v-model="form.first_name"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item class="star" label="Last Name">
          <el-input
            v-model="form.last_name"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item class="star" label="Phone Number ">
          <el-input
            v-model="form.phone_number"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item label="Position">
          <el-input
            v-model="form.position"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Please indicate to which National Organization you would like to contribute to as part of the 2024 Incentive Program? "
        >
          <el-input
            v-model="form.question_one"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item class="star" label="Membership level">
          <!-- <el-input v-model="form.email" placeholder="Default Data"></el-input> -->
          <el-select
            placeholder="Default Data"
            style="width:100%"
            v-model="form.membership_level"
            clearable
          >
            <el-option
              v-for="item in memberLevel"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="star" label="Company Address">
          <el-input
            v-model="form.company_address"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item label="Company website">
          <el-input
            v-model="form.company_website"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="star"
          label="Company logo (Highest quality/ PNG Format)"
          style="margin-top: 30px;"
        >
          <section class="d-flex text_flex">
            <el-upload
              class="avatar-uploader mr-3"
              action="no"
              v-loading="loading"
              :show-file-list="false"
              :http-request="uploadIMG"
            >
              <img v-if="form.company_logo" :src="form.company_logo" alt="" />
              <img v-else src="@/assets/images/settings/camera.png" alt="" />
            </el-upload>
            <span class="text12">(Aspect ratio: 1 to 1)</span>
          </section>
        </el-form-item>
        <el-form-item
          class="star"
          :label="
            summary_config.limit
              ? `Company description(${summary_config.limit} words)`
              : 'Company description'
          "
        >
          <section class="position-relative">
            <el-input
              type="textarea"
              placeholder="Default Data"
              :rows="3"
              resize="none"
              v-model="form.company_description"
              @blur="
                val => limitInput(val, summary_config, form.company_description)
              "
              @keyup.32.native="
                e => limitInput(e, summary_config, form.company_description)
              "
            ></el-input>
            <div class="position-absolute" style="line-height: 1.5;">
              <span v-show="summary_config.warning" style="color:#F56C6C;"
                >Cannot exceed {{ summary_config.limit }} words</span
              >
            </div>
            <div
              class="position-absolute"
              style="right: 0;line-height: 1.5;"
              v-if="summary_config.limit"
            >
              <span class="text12">
                <span
                  :style="{
                    color:
                      summary_config.textNum > summary_config.limit
                        ? '#F56C6C'
                        : ''
                  }"
                >
                  {{ summary_config.textNum }}</span
                >
                / {{ summary_config.limit }}
              </span>
            </div>
          </section>
        </el-form-item>
      </el-form>
      <div class="custom-control custom-checkbox d-inline-block">
        <input
          type="checkbox"
          class="custom-control-input"
          id="privacyPolicy"
          v-model="agree"
        />
        <label class="custom-control-label" for="privacyPolicy">
          I have read and accept the Club 100's Terms of Use and
          <a class="cursor-pointer" @click="getTerm()">Privacy Policy</a>.
        </label>
      </div>
      <div class="mt-3">
        <el-button type="primary" :disabled="!agree" @click="submitForm('form')"
          >Submit</el-button
        >
      </div>
    </el-card>
    <el-dialog title="History" width="60%" :visible.sync="checkHistory">
      <el-table :data="historyList" stripe style="width: 100%">
        <el-table-column
          prop="company_name"
          show-overflow-tooltip
          label="Company Name"
        >
        </el-table-column>
        <el-table-column prop="email" show-overflow-tooltip label="Email">
        </el-table-column>
        <el-table-column
          prop="real_name"
          show-overflow-tooltip
          label="User Name"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          show-overflow-tooltip
          label="Membership level"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.membership_level == 1
                ? "Bronze - 1,000 USD"
                : scope.row.membership_level == 2
                ? "Silver - 3,000 USD"
                : scope.row.membership_level == 3
                ? "Gold - 5,000 USD"
                : "Platinum - 10,000 USD"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="100" label="Status">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.status == 1
                  ? 'text-success'
                  : scope.row.status == 2
                  ? 'text-danger'
                  : ''
              "
              >{{
                scope.row.status == 1
                  ? "Passed"
                  : scope.row.status == 2
                  ? "Rejected"
                  : "Pending"
              }}
              <el-tooltip v-if="scope.row.status == 2" :content="scope.row.reason">
                <i
                  style="color:#FB1212"
                  class="el-icon-warning"
                ></i>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="80" label="Actions">
          <template slot-scope="scope">
            <i @click="checkDet(scope.row)" style="cursor:pointer" class="el-icon-view"></i>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="History Details" :close-on-click-modal="false" width="60%" :visible.sync="historyDia">
      <el-form
        label-position="top"
        label-width="80px"
        :model="historyInfo"
        ref="form"
      >
        <el-form-item class="star" label="Company Name">
          <el-input
            v-model="historyInfo.company_name"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item class="star" label="Email">
          <el-select
            v-model="userHist"
            style="width: 100%"
            filterable
            placeholder="Please Select"
            :filter-method="filterFriends"
            clearable
            popper-class="optionsContent"
            value-key="email"
            @change="historyUser()"
          >
            <el-option
              v-for="item in userList"
              :key="item.user_id"
              :label="item.email"
              :value="item"
            >
              <el-table
                :data="[item]"
                style="width: 100%"
                :show-header="false"
                tooltip-effect="light"
              >
                <el-table-column prop="date" width="60">
                  <template slot-scope="scope">
                    <div class="flex justify-end">
                      <el-avatar
                        icon="el-icon-user-solid"
                        :src="scope.row.avatar"
                        :size="30"
                      >
                      </el-avatar>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="real_name" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="email" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="mobile" show-overflow-tooltip>
                </el-table-column>
              </el-table>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="star" label="First Name">
          <el-input
            v-model="historyInfo.first_name"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item class="star" label="Last Name">
          <el-input
            v-model="historyInfo.last_name"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item class="star" label="Phone Number ">
          <el-input
            v-model="historyInfo.phone_number"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item label="Position">
          <el-input
            v-model="historyInfo.position"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Please indicate to which National Organization you would like to contribute to as part of the 2024 Incentive Program? "
        >
          <el-input
            v-model="historyInfo.question_one"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item class="star" label="Membership level">
          <el-select
            placeholder="Default Data"
            style="width:100%"
            v-model="historyInfo.membership_level"
            clearable
          >
            <el-option
              v-for="item in memberLevel"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="star" label="Company Address">
          <el-input
            v-model="historyInfo.company_address"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item label="Company website">
          <el-input
            v-model="historyInfo.company_website"
            placeholder="Default Data"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="star"
          label="Company logo (Highest quality/ PNG Format)"
          style="margin-top: 30px;"
        >
          <section class="d-flex text_flex">
            <el-upload
              class="avatar-uploader mr-3"
              action="no"
              v-loading="loading"
              :show-file-list="false"
              :http-request="editUploadIMG"
            >
              <img
                v-if="historyInfo.company_logo"
                :src="historyInfo.company_logo"
                alt=""
              />
              <img v-else src="@/assets/images/settings/camera.png" alt="" />
            </el-upload>
            <span class="text12">(Aspect ratio: 1 to 1)</span>
          </section>
        </el-form-item>
        <el-form-item
          class="star"
          :label="
            history_config.limit
              ? `Company description(${history_config.limit} words)`
              : 'Company description'
          "
        >
          <section class="position-relative">
            <el-input
              type="textarea"
              placeholder="Default Data"
              :rows="3"
              resize="none"
              v-model="historyInfo.company_description"
              @blur="
                val =>
                  limitInput(
                    val,
                    history_config,
                    historyInfo.company_description
                  )
              "
              @keyup.32.native="
                e => limitInput(e, history_config, form.company_description)
              "
            ></el-input>
            <div class="position-absolute" style="line-height: 1.5;">
              <span v-show="history_config.warning" style="color:#F56C6C;"
                >Cannot exceed {{ history_config.limit }} words</span
              >
            </div>
            <div
              class="position-absolute"
              style="right: 0;line-height: 1.5;"
              v-if="history_config.limit"
            >
              <span class="text12">
                <span
                  :style="{
                    color:
                      history_config.textNum > history_config.limit
                        ? '#F56C6C'
                        : ''
                  }"
                >
                  {{ history_config.textNum }}</span
                >
                / {{ history_config.limit }}
              </span>
            </div>
          </section>
        </el-form-item>
      </el-form>
      <!-- <div class="custom-control custom-checkbox d-inline-block">
        <input
          type="checkbox"
          class="custom-control-input"
          id="privacyTrem"
          v-model="agreeTrem"
        />
        <label class="custom-control-label" for="privacyTrem">
          I have read and accept the Club 100's Terms of Use and
          <a
            class="cursor-pointer"
            @click="getTerm()"
            >Privacy Policy</a
          >.
        </label>
      </div> -->
      <div class="nt-3">
        <el-button type="primary" @click="submitHistory()">Submit</el-button>
      </div>
    </el-dialog>
    <el-dialog title="Privacy Policy" width="50%" :visible.sync="tremDia">
      <div v-html="trems"></div>
    </el-dialog>
  </section>
</template>

<script>
export default {
  data() {
    return {
      agree: false,
      agreeTrem: false,
      tremDia: false,
      trems: "",
      checkHistory: false,
      historyDia: false,
      historyList: [],
      historyInfo: {
        id: 0,
        company_name: "",
        user_id: "",
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        position: "",
        question_one: "",
        membership_level: "",
        company_address: "",
        company_website: "",
        company_logo: "",
        company_description: ""
      },
      history_config: {
        limit: 0,
        warning: false,
        textNum: 0
      },
      userHist: {},
      intro: "",
      form: {
        id: 0,
        company_name: "",
        user_id: "",
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        position: "",
        question_one: "",
        membership_level: "",
        company_address: "",
        company_website: "",
        company_logo: "",
        company_description: ""
      },
      rules: {},
      summary_config: {
        limit: 0,
        warning: false,
        textNum: 0
      },
      memberLevel: [
        { value: 1, label: "Bronze - 1,000 USD" },
        { value: 2, label: "Silver - 3,000 USD" },
        { value: 3, label: "Gold - 5,000 USD" },
        { value: 4, label: "Platinum - 10,000 USD" }
      ],
      userList: [],
      user: {},
      loading: false
    };
  },
  components: {},

  created() {
    this.searchUserList();
    this.getClub100Config();
  },

  methods: {
    getTerm() {
      this.tremDia = true;
    },
    submitForm(form) {
      if (
        !this.form.company_logo ||
        !this.form.company_name ||
        !this.form.email ||
        !this.form.first_name ||
        !this.form.last_name ||
        !this.form.phone_number ||
        !this.form.membership_level ||
        !this.form.company_address ||
        !this.form.company_description
      )
        return this.$message.warning("Please complete the form information");
      this.$http.submitClubApplication({ ...this.form }).then(res => {
        if (res.status == 200) {
          this.$router.push("/club");
        }
      });
    },
    submitHistory(form) {
      if (
        !this.historyInfo.company_logo ||
        !this.historyInfo.company_name ||
        !this.historyInfo.email ||
        !this.historyInfo.first_name ||
        !this.historyInfo.last_name ||
        !this.historyInfo.phone_number ||
        !this.historyInfo.membership_level ||
        !this.historyInfo.company_address ||
        !this.historyInfo.company_description
      )
        return this.$message.warning("Please complete the form information");
      this.$http.submitClubApplication({ ...this.historyInfo }).then(res => {
        if (res.status == 200) {
          this.$router.push("/club");
        }
      });
    },
    getClub100History() {
      this.$http.getClub100History().then(res => {
        if (res.status == 200) {
          this.historyList = res.data.data;
          setTimeout(() => {
            this.checkHistory = true;
          }, 100);
        }
      });
    },
    async checkDet(row) {
      this.searchUserList(row.email, "get");
      let res = await this.$http.getClub100Detail({
        id: row.id
      });
      if (res.status == 200) {
        this.historyInfo = res.data;
        setTimeout(() => {
          this.agreeTrem = false;
          this.historyDia = true;
        }, 10);
      }
    },
    historyUser() {
      this.historyInfo.user_id = this.userHist.user_id;
      this.historyInfo.email = this.userHist.email;
      this.historyInfo.first_name = this.userHist.first_name;
      this.historyInfo.last_name = this.userHist.last_name;
      this.historyInfo.phone_number = this.userHist.mobile;
    },
    chooseUser() {
      this.form.user_id = this.user.user_id;
      this.form.email = this.user.email;
      this.form.first_name = this.user.first_name;
      this.form.last_name = this.user.last_name;
      this.form.phone_number = this.user.mobile;
    },
    getClub100Config() {
      this.$http.getClub100Config().then(res => {
        this.summary_config.limit = res.data.company_description;
        this.history_config.limit = res.data.company_description;
        this.intro = res.data.become_introduction;
        this.trems = res.data.privacy_policy;
      });
    },
    searchUserList(val, type) {
      this.$http
        .searchUserList({
          keyword: val ? val : ""
        })
        .then(res => {
          this.userList = res.data;
          if (type) {
            this.userHist = res.data[0];
          }
        });
    },
    filterFriends(val) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.searchUserList(val);
      });
    },

    uploadIMG(params) {
      this.loading = true;
      var formData = new FormData();
      formData.append("file", params.file);
      this.$http.uploadImage(formData).then(res => {
        if (res.status == 200) {
          this.form.company_logo = res.data[0];
        }
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
    },
    editUploadIMG(params) {
      this.loading = true;
      var formData = new FormData();
      formData.append("file", params.file);
      this.$http.uploadImage(formData).then(res => {
        if (res.status == 200) {
          this.historyInfo.company_logo = res.data[0];
        }
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
    },
    async limitInput(val, item, keyword) {
      if (!item.limit) return;
      let res = await this.$http.getContentNumber({ keyword });
      if (res.status == 200) {
        item.textNum = res.data;
        if (res.data > item.limit) {
          item.warning = true;
        } else {
          item.warning = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.optionsContent .el-select-dropdown__item {
  height: 55px !important;
  padding: 0 !important;
}
/deep/ .el-form-item__label {
  line-height: 20px;
  word-break: normal;
  padding: 0;
}
::v-deep .el-input__inner {
  height: 45px;
  border-radius: 10px;
}
.star {
  position: relative;
}

.star::before {
  display: inline-block;
  position: absolute;
  content: "*";
  color: red;
  top: -2px;
  left: -8px;
}
.avatar-uploader {
  width: 90px;
  height: 90px;
  overflow: hidden;

  img {
    width: 90px;
    height: 90px;
  }
}
.clubTop {
  position: relative;
  padding: 20px;
  h1 {
    text-align: center;
    font-weight: 400;
    font-size: 40px;
    text-align: center;
    color: #333333;
    margin-bottom: 40px;
  }
  .top {
    margin: 40px 0;

    h3 {
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      color: #999999;
    }
  }
  /deep/ .el-form--label-top .el-form-item__label {
    padding: 0;
  }

  /* /deep/ .el-form-item__label::before, */
  .star {
    position: relative;
  }

  .star::before {
    display: inline-block;
    position: absolute;
    content: "*";
    color: red;
    top: -2px;
    left: -8px;
  }

  .avatar-uploader {
    width: 90px;
    height: 90px;
    overflow: hidden;

    img {
      width: 90px;
      height: 90px;
    }
  }
  .text12 {
    font-size: 12px;
  }
  .picture_flex {
    flex-direction: column;
  }

  .text_flex {
    align-items: flex-end;
  }
}
</style>
